/* Customize your main colors in :root variables */

:root {
  --main-background-color: #343A50;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color: #F7F6F4;
  --title-text-color: #3CBA8B;
}

@import "~antd/dist/antd.css";
body.anted {}

iframe {
  pointer-events: none !important;
  user-select: none !important;
}

body {
  transition: background-color 0.4s ease;
  &.greenary {
    background-color: #19bf0d !important;
    background-image: none !important;
    //overflow: hidden !important;
    .greened_head {
      background-color: rgb(19 79 18 / 97%);
    }
  }
}

.ant-select-dropdown {
  z-index: 999999999999999;
}

.nobg {
  background-image: none !important;
  background: none !important;
}

.background {
  background-image: url("./img/banner.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  filter: blur(7px);
  opacity: 0.7;
  transition: all 0.6s ease;
}

.top_btn {
  text-align: center;
  padding-top: 14px;
}

.top_btn button {
  border: none;
  background-color: #f3f3f3;
  outline: none;
  padding: 8px 40px;
  border-radius: 62px;
  font-size: 14px;
  color: #464646;
  cursor: pointer;
}

.background_home {
  background-image: url("./img/full_bg_home.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  filter: blur(7px);
  opacity: 0.7;
  transition: all 0.6s ease;
}

h2,
p {}

h3 {}

.bkapi {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.h-86 {
  height: 86px;
}

.flexed {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-radio-wrapper span {
  color: #fff !important;
}

.coral {
  font-size: 27px;
  padding: 120px 30px 0;
  color: #ffffffe3;
  font-weight: 100;
}

.hidden {
  display: none;
}

.customize_future {
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  color: #fff;
  opacity: 0.6;
  margin-top: 220px;
}

.customize_future_back {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  margin: 24px auto 0;
  color: #fff;
  opacity: 0.6;
  cursor: pointer;
  border-bottom: 1px dashed;
}

.modal_text {
  font-size: 14px;
  color: #000;
  padding-bottom: 62px;
}

.modal_action {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  z-index: 999999999;
  background-color: #fff;
  padding: 12px 16px 12px 0px;
  border-top: 1px solid #e9e9e9;
  box-shadow: 0 6px 12px rgb(0 0 0 / 41%);
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: #8037e5;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action a {
  color: #8037e5;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

.modal_action button:first-child {
  color: #b6b6b6;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

app-root {
  display: none;
}

@media screen and (max-width: 767px) {
  app-root {
    display: block;
  }
}

.bounceRowAnim {
  transform: scale(1);
  animation: bounceRow 1s ease-in 1s infinite;
}

section {
  max-width: 1580px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  transition: all 0.4s ease;
  background: linear-gradient(#5a06c1, #580992);
  min-height: 100vh;
  position: relative;
}

.section.purple {
  background: linear-gradient(#5a06c1, #580992);
}

.section.blue {
  background: linear-gradient(#4940ae, #251e56);
}

.section.teal {
  background: linear-gradient(#1c7878, #123d3d);
}

.section.green {
  background: linear-gradient(#367423, #1e4314);
}

.section.blpurp {
  background: linear-gradient(#47133d, #190617);
}

#fullpage {
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;
}

.bounceRowAnim2 {
  transform: scale(1);
  animation: bounceRow 1s ease-in 1.5s infinite;
}

.bounceRowAnim3 {
  transform: scale(1);
  animation: bounceRow 1s ease-in 2s infinite;
}

@keyframes bounceRow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.cld {
  text-align: center;
  color: #fff;
  width: fit-content;
  margin: 0 auto;
}

.makeStyles-root-3 .MuiDialogTitle-root {
  background-color: #262626 !important;
}

.MuiList-root.MuiList-padding {
  padding: 14px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  padding: 8px 24px !important;
}

.wpm {
  display: block;
  color: #fff;
  text-decoration: underline;
  font-weight: 600;
  margin: 10px 25px 18px;
}

.features {
  margin-top: 278px !important;
}

.nfts_animation {
  top: 248px !important;
}

@media screen and (max-width: 960px) {
  .features {
    margin-top: 224px !important;
  }
  .nfts_animation {
    top: 128px !important;
  }
}

.MuiButton-root {
  cursor: pointer;
  transition: all 0.3s ease !important;
}

.fZbbaO {
  margin-top: 22px !important;
}

.copyblock {
  z-index: 99999 !important;
}

.cL_i_title .x3 {
  width: 74px !important;
}

.nftcollection {
  margin-top: 120px !important;
}

.MuiButton-root:hover {
  transform: scale(1.1);
}

.MuiButton-contained {
  box-shadow: 0px 3px 12px #fc00ff !important;
  background: #fc00ff !important;
  background-color: #fc00ff !important;
  border: 2px solid #fc00ff !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.ChatOpenner {
  box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
  position: fixed;
  bottom: 16px;
  right: 68px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: 82px;
  z-index: 99999999999999;
}

.ChatOpenner div {
  text-align: center;
  padding: 1px 12px;
  cursor: pointer;
  border-radius: 24px;
  transition: all 0.3s ease;
  font-size: 16px;
  color: #383d6a;
}

.SettingsOpenner {
  right: 16px !important;
}

.LangChanger {
  box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
  position: fixed;
  bottom: 24px;
  right: 74px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: 82px;
  z-index: 99999999999999;
}

.LangChanger div {
  text-align: center;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 24px;
  transition: all 0.3s ease;
  font-size: 14px;
  color: #383d6a;
}

body {
  background-color: #6717d6;
}

.LangChanger div.active {
  background-color: #383d6a;
  color: #fff;
}

.LangChanger div.LC_Light {
  width: 100%;
}

.LangChanger div.LC_Dark {
  width: 100%;
}

.f_f_title {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}

.f_f_text {
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 12px;
}

.f_f_text .f1 {
  font-weight: 700;
  font-size: 28px;
}

.f_f_text .f2 {
  font-weight: 600;
}

.f_f_text .f3 {
  font-weight: 900;
  text-decoration: underline;
}

.f_f_text .f4 {
  font-weight: 900;
}

.f_f_text .f5 {
  font-weight: 700;
}

.surprised {
  overflow: hidden !important;
}

.surprised body {
  overflow: hidden !important;
}

.surprised #root {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  top: 0;
}

main .carousel>div {
  background: #6817d6 !important;
  border: 1px solid #6817d6 !important;
}

main .carousel>div:nth-child(1):before {
  border: none !important;
}

.footer {
  background-position: -350px -208px !important;
}

@media screen and (max-width: 760px) {
  .map img {}
}

@media screen and (max-width: 326px) {
  body {}
}

@media screen and (max-width: 316px) {
  body {}
}

.sippa {
  border: none;
  outline: none;
  background: transparent;
}

* {
  -webkit-appearance: none;
}

@media screen and (max-width: 960px) {
  .smb-1 {
    margin-top: 48px !important;
  }
  .smb-2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    width: 330px !important;
  }
}

.indev {
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  padding: 100px 0;
}

.ant-select {
  color: rgb(255 255 255) !important;
}

.ant-select-selector {
  height: 28px !important;
  background-color: #00000052 !important;
  border: 1px solid #4f4f4f !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 26px !important;
  color: #000;
}

.ant-select-selection-item {
  line-height: 28px !important;
}

.ant-select-arrow {
  color: rgb(255 255 255 / 70%);
}

.modal_text {}

.notify_state {
  background-color: #f1f1f1;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 12px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notify_state b {
  margin-right: 6px;
}

.notify_state span {
  width: 10px;
  height: 10px;
  margin-left: 12px;
  border-radius: 100px;
  display: inline-block;
}

.Toastify__toast-container {
  width: calc(100vw - 20px) !important;
  margin: 10px !important;
}

.Toastify__toast {
  background: #fff !important;
  border-radius: 12px !important;
  color: #212121b3 !important;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 12%) !important;
  margin-bottom: 12px !important;
}

.supered {}

.supered p {
  width: 100%;
  overflow: hidden;
  line-break: anywhere;
  text-align: center;
}

.supered p span {
  font-size: 14px;
  background-color: #7002e017;
  padding: 6px 8px;
  border-radius: 6px;
  display: block;
  width: fit-content;
  color: #5401a8;
  font-weight: 700;
  margin: 6px auto 0px;
}

.supered p span b {
  margin-left: 4px;
}

.ant-popover {
  z-index: 99999999999999 !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-popover-inner {
  border-radius: 12px !important;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 24px 0 rgb(0 0 0 / 16%), 0 9px 28px 10px rgb(0 0 0 / 9%) !important;
}

.chat_theme_dark .ant-popover {}

.chat_theme_dark .ant-popover-arrow-content {
  --antd-arrow-background-color: #2e2e2e;
}

.chat_theme_dark .ant-popover-inner {
  background-color: #2c2c2c;
}

.chat_theme_dark .ant-popover-title {
  color: rgb(239 239 239 / 85%);
  border-bottom: 1px solid #454545;
}

.chat_theme_dark #clickbox div {
  border-bottom: 1px solid #3a3a3a;
  color: #fff;
}

.chat_theme_dark #clickbox div:last-child {
  border-bottom: none !important;
  color: #fff;
}

.chat_theme_dark #clickbox2 div {
  border-bottom: 1px solid #3a3a3a;
  color: #fff;
}

.chat_theme_dark #clickbox2 div:last-child {
  border-bottom: none !important;
  color: #fff;
}

.ant-popover-title {
  font-size: 16px;
  padding: 9px 16px;
  font-weight: 600 !important;
}

:root {
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
}

.toggle-switch {
  position: relative;
  width: 200px;
}

.toggle-switch label {
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: var(--dark);
  border-radius: 50px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  display: none;
}

.toggle-switch .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: 0.3s;
}

.toggle-switch input:checked~.slider {
  background-color: var(--light);
}

.toggle-switch .slider::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 16px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: inset 28px -4px 0px 0px var(--light);
  background-color: var(--dark);
  transition: 0.3s;
}

.toggle-switch input:checked~.slider::before {
  transform: translateX(95px);
  background-color: var(--dark);
  box-shadow: none;
}

#toggle_checkbox {
  display: none;
}

label {
  display: block;
  position: relative;
  width: 48px;
  height: 24px;
  margin: 0 auto;
  background-color: #77b5fe;
  border-radius: 56px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.3s ease background-color;
  overflow: hidden;
}

#star {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  background-color: #fafd0f;
  transform: scale(1);
  border-radius: 50%;
  transition: 0.3s ease top, 0.3s ease left, 0.3s ease transform, 0.3s ease background-color;
  z-index: 1;
}

#star-1 {
  position: relative;
}

#star-2 {
  position: absolute;
  transform: rotateZ(36deg);
}

.star {
  top: 0;
  left: -3px;
  font-size: 20px;
  line-height: 12px;
  color: #fafd0f;
  transition: 0.3s ease color;
}

#moon {
  position: absolute;
  bottom: -52px;
  right: 8px;
  width: 13px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s ease bottom;
}

#moon:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -7px;
  width: 14px;
  height: 14px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s ease background-color;
}

#toggle_checkbox:checked+label {
  background-color: #222;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}

#toggle_checkbox:checked+label #star {
  top: 3px;
  left: 64px;
  transform: scale(0.3);
  background-color: yellow;
}

#toggle_checkbox:checked+label .star {
  color: yellow;
}

#toggle_checkbox:checked+label #moon {
  bottom: 7px;
}

#toggle_checkbox:checked+label #moon:before {
  background-color: #222;
}

.anted-selecting .ant-select-selector {
  height: unset !important;
  background-color: transparent !important;
  border: none !important;
}

.anted-selecting {
  border: 2px solid #dedede;
  padding: 7px 7px 5px;
  border-radius: 6px;
  outline-color: #6617d6;
  margin-bottom: 10px;
}

.anted-selecting input {
  outline: none !important;
  border: none !important;
}

.anted-selecting .ant-select-selector {
  box-shadow: none !important;
  border-color: transparent !important;
}

.supertitle {
  color: #fff!important;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 600;
  text-shadow: 0px 0px 6px #fc00ff!important;
  text-transform: uppercase;
  text-align: center;
}

.supertitleb {
  color: #fff!important;
  font-size: 56px;
  font-family: Montserrat;
  font-weight: 700;
  text-shadow: 0px 0px 6px #00a6ff !important;
  text-transform: uppercase;
  text-align: center;
}

.f1 {
  font-weight: 700;
  font-size: 28px;
}

.f2 {
  font-weight: 600;
}

.f3 {
  font-weight: 900;
  text-decoration: underline;
}

.f4 {
  font-weight: 900;
}

.f5 {
  font-weight: 700;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.kGHhkU {
  margin-top: 162px !important;
}

#winter-wrap {
  background-color: #6717d6;
  background: linear-gradient(20deg, rgb(21, 0, 36) 0%, rgb(58, 9, 121) 0%, rgb(158, 106, 227) 76%, rgb(123, 0, 255) 100%);
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  /*   border-top: 5px solid #ddd; */
  border-bottom-color: #fff;
  margin: 0 0;
  padding: 0;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
  box-shadow: inset 0 -50px 100px 50px #6717d6, inset 0 -100px 100px 75px #25024a, inset 0 50px 150px 10px #fff, 0 -15px 10px -10px #8846c5;
  animation: skyflash 24s infinite alternate linear;
}

@media screen and (max-width: 468px) {
  #winter-wrap.hhh {
    left: -7vw !important;
    width: 130vw !important;
    max-width: 130vw !important;
    height: 150vh !important;
  }
}

.posrel {
  position: relative;
  z-index: 999999;
}

#winter-wrap.hhh {}

#winter-wrap.hhh::before {
  content: none !important;
}

#winter-wrap.hhh::after {
  content: none !important;
}

#winter-wrap.cstmz::before {
  content: none !important;
}

#winter-wrap.cstmz::after {
  content: none !important;
}

@keyframes skyflash {
  0%,
  100% {
    box-shadow: inset 0 -50px 100px 50px #6717d6, inset 0 100px 100px 75px #2c024a, inset 0 50px 150px 10px #fff, 0 -15px 10px -10px #7f46c5;
  }
  33%,
  66% {
    box-shadow: inset 0 -50px 100px 50px #6717d6, inset 0 -100px 100px 75px #2b024a, inset 0 50px 150px 10px #fff, 0 -15px 10px -10px #7746c5;
  }
}

#winter-wrap::before {}

#winter-wrap::after {
  content: "";
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 2;
  overflow: hidden;
  box-shadow: inset 0px -50px 50px -10px rgba(255, 255, 255, 0.565);
}

#winter-wrap ul {
  padding: 0;
  margin: 0;
}

.ground::after {
  content: "";
  width: 400px;
  height: 130px;
  border-radius: 100% 50% 0% 50%;
  padding: 0;
  margin: 0;
  background: #fff;
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: calc(50% - 210px);
  box-shadow: inset -40px 0px 20px 15px #cbd7e6, -130px -5px 30px -10px #fff, -150px -20px 0 -10px #b3c5da, 130px 35px 30px -30px #fff, 145px 20px 20px -20px #f5efe7, 150px 20px 0 -15px #b3c5da, 150px 20px 10px -16px #fff;
}

.ground::before {
  content: "";
  width: 300px;
  height: 150px;
  border-radius: 60% 100% 0% 0%;
  padding: 0;
  margin: 0;
  background: #fff;
  position: absolute;
  z-index: 2;
  bottom: -66px;
  right: 33px;
  box-shadow: inset -50px 10px 40px 0px #cbd7e6;
}

.tree-wrap {
  position: absolute;
  width: 100%;
  height: 366px;
  bottom: 110px;
}

.tree-wrap::before {
  content: "";
  width: 150px;
  height: 50px;
  background: black;
  box-shadow: 0 0 75px 50px black;
  border-radius: 100% 100% 0 0;
  position: absolute;
  z-index: 1;
  left: calc(50% - 110px);
  bottom: 75px;
}

.tree-wrap::after {
  content: "";
  width: 50px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: #611919;
  position: absolute;
  bottom: 0;
  left: calc(50% - 25px);
  box-shadow: inset -10px 10px 22px 2px black, inset 10px -10px 15px 0 #a56f20;
}

.tree {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid #174821;
  position: absolute;
  left: calc(50% - 100px);
  list-style: none;
}

.top {
  transform: rotateY(30deg);
  transform-origin: center;
  top: 35px;
  z-index: 3;
}

.middle {
  transform: rotateY(30deg) scale(1.4);
  transform-origin: center;
  top: 100px;
  z-index: 2;
}

.bottom {
  transform: rotateY(30deg) scale(2);
  transform-origin: center;
  top: 180px;
  z-index: 1;
}

.tree li {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0px;
}

.tree li:first-child {
  border-left: 100px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 100px solid #1f781d;
  transform: rotateY(40deg) rotateZ(-5deg);
  transform-origin: top right;
  left: calc(50% - 100px);
}

.tree li:nth-child(2) {
  border-left: 0px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid #1b612b;
  transform: rotateY(-50deg) rotateZ(5deg);
  transform-origin: bottom left;
}

.tree li:nth-child(3) {
  border-left: 0px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid #559c5a;
  transform: rotateY(253deg) rotateZ(7deg);
  transform-origin: bottom left;
}

.tree li:nth-child(4) {
  border-left: 0px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid #1f781d;
  transform: rotateY(-75deg) rotateZ(9deg);
  transform-origin: bottom left;
}

.tree-wrap.second-tree {
  margin-top: -30%;
  margin-left: -33%;
  transform: scale(0.8);
  z-index: 0;
  bottom: 80px;
}

.tree-wrap.third-tree {
  margin-top: -30%;
  margin-left: 33%;
  transform: scale(0.5);
  z-index: 0;
  bottom: 10px;
}


/* snow */

.snow {
  background: white;
  border-radius: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  z-index: 99;
  box-shadow: 0 0px 2px 0px #fff, 10px 10px 0px 0 #fff, 50px 20px 2px 0 #fff, 100px 30px 2px 0 #fff, 30px 70px 2px 0 #fff, 130px 70px 2px 0px #fff, -30px 30px 2px 0 #fff, -55px 45px 2px 0 #fff, -70px 30px 2px 0 #fff, -130px 40px 2px 0 #fff, -150px 90px 2px 0 #fff, -160px 80px 2px 0 #fff, -190px 130px 2px 0 #fff, -150px 150px 2px 0 #fff, -130px 120px 2px 0 #fff, -100px 130px 2px 0 #fff, -90px 80px 2px 0 #fff, -40px 100px 2px 0 #fff, -30px 90px 2px 0 #fff, 0px 100px 2px 0 #fff, 40px 140px 2px 0 #fff, 70px 120px 2px 0 #fff, 50px 180px 2px 0 #fff, 100px 150px 2px 0 #fff, 140px 120px 2px 0 #fff, 170px 90px 2px 0 #fff, 170px 130px 2px 0 #fff, 160px 140px 2px 0 #fff, 170px 130px 2px 0 #fff, 10px 130px 2px 0 #fff, 180px 180px 2px 0 #fff, 200px 160px 2px 0 #fff, 200px 260px 2px 0 #fff, 250px 195px 2px 0 #fff, -200px 160px 2px 0 #fff, -200px 180px 2px 0 #fff, -220px 185px 2px 0 #fff, -150px 200px 2px 0 #fff, -100px 250px 2px 0 #fff, -80px 195px 2px 0 #fff, -190px 280px 2px 0 #fff, -150px 350px 2px 0 #fff, -250px 250px 2px 0 #fff, 150px 350px 2px 0 #fff, 100px 230px 2px 0 #fff;
  -webkit-animation: snow 20s linear infinite;
  -moz-animation: snow 20s linear infinite;
  -ms-animation: snow 20s linear infinite;
  animation: snow 20s linear infinite;
}

.snow::after {
  content: "";
  background: white;
  border-radius: 100%;
  left: 100px;
  position: absolute;
  top: -200px;
  width: 5px;
  height: 5px;
  z-index: 99;
  box-shadow: 0 0px 2px 0px #fff, 10px 10px 0px 0 #fff, 50px 20px 2px 0 #fff, 100px 30px 2px 0 #fff, 30px 70px 2px 0 #fff, 130px 70px 2px 0px #fff, -30px 30px 2px 0 #fff, -55px 45px 2px 0 #fff, -70px 30px 2px 0 #fff, -130px 40px 2px 0 #fff, -150px 90px 2px 0 #fff, -160px 80px 2px 0 #fff, -190px 130px 2px 0 #fff, -150px 150px 2px 0 #fff, -130px 120px 2px 0 #fff, -100px 130px 2px 0 #fff, -90px 80px 2px 0 #fff, -40px 100px 2px 0 #fff, -30px 90px 2px 0 #fff, 0px 100px 2px 0 #fff, 40px 140px 2px 0 #fff, 70px 120px 2px 0 #fff, 50px 180px 2px 0 #fff, 100px 150px 2px 0 #fff, 140px 120px 2px 0 #fff, 170px 90px 2px 0 #fff, 170px 130px 2px 0 #fff, 160px 140px 2px 0 #fff, 170px 130px 2px 0 #fff, 10px 130px 2px 0 #fff, 180px 180px 2px 0 #fff, 200px 160px 2px 0 #fff, 200px 260px 2px 0 #fff, 250px 195px 2px 0 #fff, -200px 160px 2px 0 #fff, -200px 180px 2px 0 #fff, -220px 185px 2px 0 #fff, -150px 200px 2px 0 #fff, -100px 250px 2px 0 #fff, -80px 195px 2px 0 #fff, -190px 280px 2px 0 #fff, -150px 350px 2px 0 #fff, -250px 250px 2px 0 #fff, 150px 350px 2px 0 #fff, 100px 230px 2px 0 #fff;
}

.snow::before {
  content: "";
  background: white;
  border-radius: 100%;
  left: -200px;
  position: absolute;
  top: -300px;
  width: 5px;
  height: 5px;
  z-index: 99;
  box-shadow: 0 0px 2px 0px #fff, 10px 10px 0px 0 #fff, 50px 20px 2px 0 #fff, 100px 30px 2px 0 #fff, 30px 70px 2px 0 #fff, 130px 70px 2px 0px #fff, -30px 30px 2px 0 #fff, -55px 45px 2px 0 #fff, -70px 30px 2px 0 #fff, -130px 40px 2px 0 #fff, -150px 90px 2px 0 #fff, -160px 80px 2px 0 #fff, -190px 130px 2px 0 #fff, -150px 150px 2px 0 #fff, -130px 120px 2px 0 #fff, -100px 130px 2px 0 #fff, -90px 80px 2px 0 #fff, -40px 100px 2px 0 #fff, -30px 90px 2px 0 #fff, 0px 100px 2px 0 #fff, 40px 140px 2px 0 #fff, 70px 120px 2px 0 #fff, 50px 180px 2px 0 #fff, 100px 150px 2px 0 #fff, 140px 120px 2px 0 #fff, 170px 90px 2px 0 #fff, 170px 130px 2px 0 #fff, 160px 140px 2px 0 #fff, 170px 130px 2px 0 #fff, 10px 130px 2px 0 #fff, 180px 180px 2px 0 #fff, 200px 160px 2px 0 #fff, 200px 260px 2px 0 #fff, 250px 195px 2px 0 #fff, -200px 160px 2px 0 #fff, -200px 180px 2px 0 #fff, -220px 185px 2px 0 #fff, -150px 200px 2px 0 #fff, -100px 250px 2px 0 #fff, -80px 195px 2px 0 #fff, -190px 280px 2px 0 #fff, -150px 350px 2px 0 #fff, -250px 250px 2px 0 #fff, 150px 350px 2px 0 #fff, 100px 230px 2px 0 #fff;
}

@keyframes snow {
  0% {
    transform: translate(0, 0);
    opacity: 0.15;
  }
  20% {
    transform: translate(-20px, 100px);
    opacity: 1;
  }
  70% {
    transform: translate(-70px, 250px);
    opacity: 0.7;
  }
  100% {
    transform: translate(-100px, 300px);
    opacity: 0.1;
  }
}

@-moz-keyframes snow {
  0% {
    transform: translate(0, 0);
    opacity: 0.15;
  }
  20% {
    transform: translate(-20px, 100px);
    opacity: 1;
  }
  70% {
    transform: translate(-70px, 250px);
    opacity: 0.7;
  }
  100% {
    transform: translate(-100px, 300px);
    opacity: 0.1;
  }
}

@-webkit-keyframes snow {
  0% {
    transform: translate(0, 0);
    opacity: 0.15;
  }
  20% {
    transform: translate(-20px, 100px);
    opacity: 1;
  }
  70% {
    transform: translate(-70px, 250px);
    opacity: 0.7;
  }
  100% {
    transform: translate(-100px, 300px);
    opacity: 0.1;
  }
}

@-ms-keyframes snow {
  0% {
    transform: translate(0, 0);
    opacity: 0.15;
  }
  20% {
    transform: translate(-20px, 100px);
    opacity: 1;
  }
  70% {
    transform: translate(-70px, 250px);
    opacity: 0.7;
  }
  100% {
    transform: translate(-100px, 300px);
    opacity: 0.1;
  }
}

.snow2 {
  content: "";
  position: absolute;
  top: -100px;
  left: 50%;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 0.8;
  -webkit-animation: slowSnow 40s linear infinite;
  -moz-animation: slowSnow 40s linear infinite;
  -ms-animation: slowSnow 40s linear infinite;
  animation: slowSnow 40s linear infinite;
  box-shadow: 0 0px 6px 0px #fff, -20px 50px 4px 1px #fff, 80px 70px 4px 1px #fff, 200px 150px 4px 1px #fff, -200px 150px 4px 1px #fff, -100px 100px 4px 1px #fff, 90px 180px 4px 1px #fff, 190px 280px 4px 1px #fff, -150px 250px 4px 1px #fff, -190px 280px 4px 1px #fff, -190px 80px 4px 1px #fff, -250px 200px 4px 1px #fff, -270px 380px 4px 1px #fff, 270px 330px 4px 1px #fff, -200px 390px 4px 1px #fff, -240px 310px 4px 1px #fff, 180px 380px 4px 1px #fff;
}

.snow2::before {
  content: "";
  position: absolute;
  top: -500px;
  left: 50%;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 0.8;
  -webkit-animation: slowSnow 30s linear infinite;
  -moz-animation: slowSnow 30s linear infinite;
  -ms-animation: slowSnow 30s linear infinite;
  animation: slowSnow 30s linear infinite;
  box-shadow: 0 0px 6px 0px #fff, -20px 50px 4px 1px #fff, 80px 70px 4px 1px #fff, 200px 150px 4px 1px #fff, -200px 150px 4px 1px #fff, -100px 100px 4px 1px #fff, 90px 180px 4px 1px #fff, 190px 280px 4px 1px #fff, -150px 250px 4px 1px #fff, -190px 280px 4px 1px #fff, -190px 80px 4px 1px #fff, -250px 200px 4px 1px #fff, -270px 380px 4px 1px #fff, 270px 330px 4px 1px #fff, -200px 390px 4px 1px #fff, -240px 310px 4px 1px #fff, 180px 380px 4px 1px #fff;
}


/* slowSlow */

@keyframes slowSnow {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-20px, 100px);
  }
  70% {
    transform: translate(0px, 250px);
  }
  100% {
    transform: translate(10px, 300px);
  }
}


/* sun */

.sun {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #ff0;
  background: radial-gradient(ellipse at center, #f90 0%, #fc0 60%, #ff0 100%);
  position: absolute;
  bottom: -150px;
  left: 0;
  z-index: 0;
  transform-origin: 300px -150px;
  animation: suntrack 24s infinite forwards linear, sunflash 24s infinite forwards linear;
}

@keyframes suntrack {
  from {
    transform: rotate(.15turn);
  }
  to {
    transform: rotate(.85turn);
  }
}

@keyframes sunflash {
  0%,
  66% {
    box-shadow: inset 0 0 24px 75px #f90, 0 0 0 0 #f90;
  }
  25%,
  33% {
    box-shadow: inset 0px 0 0 0 #f90, 0 0 5px 2px #fc0;
  }
}

.hapka {
  position: absolute;
  top: -5px;
  left: 11px;
  transform: skew(83deg, -96deg) scale(0.10) rotate(-56deg);
}

.hapka img {
  width: 20px;
}

.hapka_home {
  top: 10px !important;
  left: 14px !important;
}

.hapka_hh {
  top: 21px !important;
  left: 15px !important;
}

.loading {
  text-align: center;
  padding: 160px 0;
}

.hapka {
  // display: none !important;
}

* {
  scrollbar-color: rgba(48, 70, 95, 0.225);
  scrollbar-width: 4px;
}

*::-webkit-scrollbar-track-piece {
  background: transparent;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

@media screen and (min-width: 768px) {
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

*::-webkit-scrollbar-track {
  background-color: rgba(95, 55, 48, 0);
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(78, 18, 118, 0.5);
  border-radius: 40px;
  width: 6px;
}

.mbc-btn {
  margin-top: 0px;
  box-shadow: 0px 3px 12px #761ad4 !important;
  background-color: #761ad4 !important;
  border: 1px solid #761ad4;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  outline: none !important;
  border-radius: 12px;
  padding: 8px 10px;
  cursor: pointer;
}

.mbc-btn:hover {
  color: #fff !important;
}

.leBEGy {
  margin-bottom: 280px !important;
}

.ant-switch-checked {
  background-color: #fc00ff !important;
}

.ant-switch {
  background-color: rgb(255 255 255 / 25%);
}

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  padding: 16px 32px;
  margin: 8px 0 0;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4d4 !important;
  border-radius: 12px;
  font-size: 16px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  border: none;
}

.animated-button1.miniz {
  padding: 10px 22px;
  width: fit-content;
  margin-top: 0px;
  letter-spacing: 0.5px !important;
}

.animated-button1.mini {
  padding: 10px 12px;
  width: 100%;
  margin-top: 0px;
  letter-spacing: 0.5px !important;
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
  border-radius: 12px;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 26px !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
}

.wallet-adapter-dropdown {
  width: 100%;
}

.wallet-adapter-button {
  width: 100%;
  justify-content: center;
  height: 42px !important;
}

.wallet-adapter-dropdown-list {
  z-index: 999999999999 !important;
}

.wallet-adapter-dropdown-list {
  top: 32px !important;
}

.wallet-adapter-modal-title {
  padding: 54px 48px 38px 48px !important;
}

.wallet-adapter-button {
  margin-left: 12px;
  margin-right: 12px;
  width: calc(100% - 24px) !important;
  border-radius: 6px !important;
  padding: 18px 18px !important;
}

@media screen and (min-width: 960px) {
  .Toastify__toast-container--top-right {
    right: 1em !important;
    top: 1em !important;
    max-width: 510px !important;
    left: unset !important;
  }
}

#fp-nav.right {
  right: 2px;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #fff6;
  backdrop-filter: blur(8px);
}

.blockquote-2 {
  font-size: 14px;
}

.blockquote-2 p::before {
  content: "“";
  font-family: Georgia;
  font-size: 40px;
  line-height: 0;
  display: inline-block;
}

.blockquote-2 cite {
  float: right;
  font-size: 13px;
}

.blockquote-2 cite:before {
  content: '- ';
}