.setting_block {
  .setting_block__title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .setting_block__content {}
  .setting_block__items {
    position: relative;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    border-radius: 6px;
    z-index: 999;
    width: fit-content;
    margin-bottom: 18px;
    div {
      text-align: center;
      padding: 4px 12px;
      cursor: pointer;
      border-radius: 6px;
      transition: all 0.3s ease;
      font-size: 14px;
      color: #383d6a;
      &.active {
        background-color: #4d43cd;
        color: #fff;
      }
      &.LC_Light {
        width: 100%;
      }
      &.LC_Dark {
        width: 100%;
      }
    }
  }
}